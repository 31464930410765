import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { shape, string } from "prop-types";
import Email from "./items/email";
import Phone from "./items/phone";

const config = require("../../config.json")
const client = config.APP_CLIENT;
const imgName = config.APP_IMG_NAME;
const compagnyName = config.APP_COMPAGNY_NAME;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;
const colorBackgroundTable = config.APP_COLORBACKGROUNDTABLE;
const widthImage = config.APP_WIDTHIMAGE;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(35vh);
  @media (max-width: 1280px) {
    transform: translateY(0vh);
  }
`;
const SignatureStyle = {
  borderSpacing: "25px",
  backgroundColor: colorBackgroundTable,
};
const ImageWrapper = {
  // display: "block",
  position: "relative",
  width: widthImage,
  height: widthImage,
  marginLeft: "20px",
  objectFit: "cover",
  minWidth: "200px",
  borderRight: "1px solid #004289"
};
const imgLeft = {
  position: "absolute",
  height: "100%",
  width: "100%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const imgLeftCenter = {
  position: "absolute",
  height: "100%",
  width: "100%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const Image = {
  width: "100%",
  height: "100%",
  margin: "auto",
  display: "block",
};
const ImageObject = {
  width: widthImage,
  height: "100%",
  margin: "auto",
  display: "block",
  objectFit: "cover",
};
const TextWrapper = {
  marginLeft: "20px",
  fontFamily: "Arial",
  lineHeight: "1.45",
  minWidth: "200px",
};
const Name = {
  fontSize: "20px",
  margin: "0",
  fontWeight: "700",
  lineHeight: "1",
  display: "inline-block",
};
const firstName = {
  fontSize: "20px",
  margin: "0",
  fontWeight: "700",
  lineHeight: "1",
  display: "inline-block",
  textTransform: "capitalize"
};
const Job = {
  margin: "3px 0px 15px 0px",
  fontSize: "11px",
  color: compagnyColorPrimary,
  fontWeight: "normal",
  width: "250px",
  fontStyle: "italic",
};
const Link = {
  color: "#002B52",
  fontWeight: "bold",
  fontSize: "15px",
  textDecoration: "none"
};
const StandardURL = {
  margin: "7px 0px 15px 0px",
  color: "#002B52",
  fontSize: "11px"
};
const Slogan = {
  margin: "0px 0px",
  fontSize: "9px",
  color: "black",
  fontWeight: "normal",
  whiteSpace: "pre-line",
  fontFamily: 'Arial',
  fontStyle: "normal",
  lineHeight: "10px"
};
const PostalAdresse = {
  margin: "10px 0 10px 0",
  fontSize: "10px",
  color: compagnyColorPrimary,
  fontWeight: "normal",
  whiteSpace: "pre-line",
  fontFamily: 'Arial',
  fontStyle: "normal",
  lineHeight: "11px"
};
const name = {
  marginBottom: "4px"
};
const imgSocial = {
  paddingRight: "10px",
  width: "12px",
  height: "12px"
};
const SloganContainer = {
  display: "flex",
  alignItems: "center",
  margin: "0px"
};
const SloganItalic = {
  margin: "10px 0 10px 0",
  fontSize: "9px",
  fontStyle: "italic",
  lineHeight: "1.38",
  color: "#A7A7A7",
  fontWeight: "normal",
  whiteSpace: "pre-line",
}

function constructImgUrl(client, ext) {
  if (ext) {
    return `https://files.myclientisrich.com/${client}/${imgName}.${ext}`;
  }
}

const SignatureFormatter = ({ user, message, file, typeImage }) => {
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const userJob = capitalizeFirstLetter(user.job);
  let urlUploadsImg = "";
  if (user.urlImage === "" || user.urlImage === null) {
    if (typeImage === "static") {
      urlUploadsImg = constructImgUrl(client, 'png');
    } else if (typeImage === "gif") {
      urlUploadsImg = constructImgUrl(client, 'gif');
    }
  }

  let positionImage = false;
  let positionLeft;
  if (message === true) {
    positionImage = ImageObject;
    positionLeft = imgLeftCenter
  } else {
    positionImage = Image;
    positionLeft = imgLeft
  }

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {compagnyName} | Signature
        </title>
      </Helmet>
      <div className='wrapper-table'>
        <table className="signature" style={SignatureStyle}>
          <tbody>
            <tr>
              <td className="image__wrapper" style={ImageWrapper}>
                {user.urlImage ? (
                  <a style={positionLeft} href={`https://${user.urlImage}`} target='_blank' rel="noreferrer">
                    <img src={urlUploadsImg} style={positionImage} alt="logo" />
                  </a>
                ) : (
                  <a style={positionLeft} href={`https://${user.urlImage}`} target='_blank' rel="noreferrer">
                    <img src={urlUploadsImg} style={positionImage} alt="logo" />
                  </a>
                )}
              </td>
              <td className="text__wrapper" style={TextWrapper}>
                <span style={name}>
                  <span style={Object.assign({}, firstName, { color: compagnyColorPrimary })}>
                    {user.firstname}
                    &nbsp;
                  </span>
                  <span style={Object.assign({}, Name, { color: compagnyColorPrimary, textTransform: "uppercase" })}>
                    {user.lastname}
                  </span>
                </span>
                <p style={Job}>{userJob}</p>
                {user.phone ? (
                  <Phone phone={user.phone} />
                ) : null}
                {user.email ? (
                  <Email email={user.email} />
                ) : null}
                {user.postalAdress ? (
                  <p style={PostalAdresse}
                    dangerouslySetInnerHTML={{ __html: user.postalAdress }}>
                  </p>
                ) : null}
                <p style={StandardURL}>
                  <a href={`https://${user.website}`} style={Link} target="_blank" rel="noreferrer noopener">{user.website}</a>
                </p>
                {user.slogan ? (
                  <p style={SloganContainer}>
                    <img style={imgSocial} src={`https://files.myclientisrich.com/${client}/Vector.png`} width="12" height="12" alt="logo" />
                    <span style={Slogan}
                      dangerouslySetInnerHTML={{ __html: user.slogan }}>
                    </span>
                  </p>
                ) : null}
                {user.infos ? (
                  <p style={SloganItalic}
                    dangerouslySetInnerHTML={{ __html: user.infos }}>
                  </p>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

SignatureFormatter.propTypes = {
  user: shape({
    name: string.isRequired,
    job: string.isRequired,
    availability: string,
    phone: string
  }).isRequired
};

export default SignatureFormatter;
