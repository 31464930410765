import React from "react";
const config = require("../../../config.json")
const compagnyColorSecondary = config.APP_COMPAGNYCOLORSECONDARY;

const PhoneStyle = {
  fontSize: "12px",
  color: compagnyColorSecondary,
  margin: "0px 0px 0px"
};
const PhoneLink = {
  textDecoration: "none",
  color: compagnyColorSecondary,
  paddingRigth: "5px",
  marginRigth: "5px",
  lineHeight: "0",
  fontWeight: "700",

};

function telWithoutSpaces(number) {
  return number.replace(/\s/g, "");
}

//function that add space between each 2 numbers
function addSpace(number) {
  //test if there is space in number
  if (number.includes(" ")) {
    return number;
  }
  let result = "";
  for (let i = 0; i < number.length; i++) {
    if (i % 2 === 0 && i !== 0) {
      result += " ";
    }
    result += number[i];
  }
  return result;
}

export default function Phone(phone) {
  return (
    <p style={PhoneStyle}>
      <a
        style={PhoneLink}
      >
        {addSpace(phone.phone)}
      </a>
      <br />
    </p>
  );
}
