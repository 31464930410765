import React, { useState } from "react";
import SignatureFormatter from "./components/SignatureFormatter";
import styled from "@emotion/styled";
import "./style.css";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 100%;
  display: block;
`;
const InputWrapper = styled.div`
  margin: 0 0;
  padding-top: 20px;
`;
const leftPart = {
  width: "100%"
}
const inputWrapper = {
  display: "flex",
  flexDirection: "row",
  gap: "15px"
}

const userData = {
  firstname: "Prénom",
  lastname: "Nom",
  urlImage: "",
  job: "Titre du poste",
  phone: "03 XX XX XX XX",
  postalAdress: "5 Rue de l’industrie <br> 67116 REICHSTETT <br> Tél. 03 88 18 24 40",
  email: "Adresse email",
  website: "www.schoro.fr",
  linkedin: "linkedin.com",
  facebook: "facebook.com",
  instagram: "instagram.com",
  fax: "fax",
  slogan: "N'imprimez ce mail que si nécessaire.",
  infos: "Mon courriel n’appelle pas de réponse <br> en dehors des horaires habituels de travail."
};

export default function App() {
  const [user, setUser] = useState(userData);
  const [isChecked, setIsChecked] = useState(false);
  const [typeImage, setTypeImage] = useState('static');

  const userInfoChange = e => {
    const { name, value } = e.target;

    if (e.key === "Enter") {
      setUser({ ...user, [name]: value + "<br>" });
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  function copyToClipboard() {
    let tableToCopy = document.querySelector('.wrapper-table')
    let range = document.createRange();
    range.selectNode(tableToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }

  function responseCopy() {
    let button = document.querySelector('button');
      button.classList.add('copied');
    setTimeout(() => {
      button.classList.remove('copied');
    }, 2000);
  }

  return (
    <Wrapper>
      <Container>
        <section className="formulaire">
          <div style={leftPart}>
            <SignatureFormatter user={user} message={isChecked} typeImage={typeImage} />
          </div>
          <div className="rightPart">
            <h1 className="title">Signature Generator</h1>
            <h2 className="title">By my client is rich</h2>
            <p className="inputLabel">Noms</p>
            {/* ***************          NOM ET PRENOM        *************** */}
            <InputWrapper style={inputWrapper}>
              <input
                name="lastname"
                onChange={userInfoChange}
                value={user.lastname}
                placeholder="Nom"
                onBlur={() => {
                  user.lastname === "" ? setUser({ ...user, lastname: userData.lastname }) : setUser({ ...user, lastname: user.lastname });
                }}
                onFocus={() => {
                  user.lastname === userData.lastname ? setUser({ ...user, lastname: "" }) : setUser({ ...user, lastname: user.lastname });
                }}
              />
              <input
                name="firstname"
                onChange={userInfoChange}
                value={user.firstname}
                placeholder="Prénom"
                onBlur={() => {
                  user.firstname === "" ? setUser({ ...user, firstname: userData.firstname }) : setUser({ ...user, firstname: user.firstname });
                }}
                onFocus={() => {
                  user.firstname === userData.firstname ? setUser({ ...user, firstname: "" }) : setUser({ ...user, firstname: user.firstname });
                }}
              />
            </InputWrapper>
            {/* ***************          TITRE DU POSTE        *************** */}
            <InputWrapper>
              <input
                name="job"
                onChange={userInfoChange}
                value={user.job}
                placeholder="Titre du poste"
                onBlur={() => {
                  user.job === "" ? setUser({ ...user, job: userData.job }) : setUser({ ...user, job: user.job });
                }}
                onFocus={() => {
                  user.job === userData.job ? setUser({ ...user, job: "" }) : setUser({ ...user, job: user.job });
                }}
              />
            </InputWrapper>
            <p className="inputLabel">Coordonnées</p>
            {/* ***************          ADRESSE POSTALE        *************** */}
            <InputWrapper>
              <textarea
                name="postalAdress"
                onChange={userInfoChange}
                onKeyPress={userInfoChange}
                value={user.postalAdress}
                type="textarea"
                placeholder="Adresse postal"
                onBlur={() => {
                  user.postalAdress === "" ? setUser({ ...user, postalAdress: userData.postalAdress }) : setUser({ ...user, postalAdress: user.postalAdress });
                }}
                onFocus={() => {
                  user.postalAdress === userData.postalAdress ? setUser({ ...user, postalAdress: "" }) : setUser({ ...user, postalAdress: user.postalAdress });
                }}
              />
            </InputWrapper>
            {/* ***************          ADRESSE EMAIL        *************** */}
            <InputWrapper>
              <input
                name="email"
                onChange={userInfoChange}
                value={user.email}
                placeholder="Adresse email"
                onBlur={() => {
                  user.email === "" ? setUser({ ...user, email: userData.email }) : setUser({ ...user, email: user.email });
                }}
                onFocus={() => {
                  user.email === userData.email ? setUser({ ...user, email: "" }) : setUser({ ...user, email: user.email });
                }}
              />
            </InputWrapper>
            {/* ***************          TELEPHONE        *************** */}
            <InputWrapper>
              <input
                name="phone"
                onChange={userInfoChange}
                value={user.phone}
                placeholder="Téléphone"
                onBlur={() => {
                  user.phone === "" ? setUser({ ...user, phone: userData.phone }) : setUser({ ...user, phone: user.phone });
                }}
                onFocus={() => {
                  user.phone === userData.phone ? setUser({ ...user, phone: "" }) : setUser({ ...user, phone: user.phone });
                }}
              />
            </InputWrapper>

            {/* ***************          MESSAGE        *************** */}
            <p className="inputLabel">Message</p>
            <InputWrapper>
              <textarea
                name="slogan"
                onKeyPress={userInfoChange}
                onChange={userInfoChange}
                value={user.slogan}
                type="textarea"
                placeholder="Message"
                onBlur={() => {
                  user.slogan === "" ? setUser({ ...user, slogan: userData.slogan }) : setUser({ ...user, slogan: user.slogan });
                }}
                onFocus={() => {
                  user.slogan === userData.slogan ? setUser({ ...user, slogan: "" }) : setUser({ ...user, slogan: user.slogan });
                }}
              />
            </InputWrapper>
            {/* ***************          Infos ITALIC        *************** */}
            <p className="inputLabel">Infos</p>
            <InputWrapper>
              <textarea
                name="infos"
                onChange={userInfoChange}
                onKeyPress={userInfoChange}
                value={user.infos}
                type="textarea"
                placeholder="Message"
                onBlur={() => {
                  user.infos === "" ? setUser({ ...user, infos: userData.infos }) : setUser({ ...user, infos: user.infos });
                }}
                onFocus={() => {
                  user.infos === userData.infos ? setUser({ ...user, infos: "" }) : setUser({ ...user, infos: user.infos });
                }}
              />
            </InputWrapper>
            {/* ***************          SITE INTERNET        *************** */}
            <p className="inputLabel">Site internet</p>
            <InputWrapper>
              <input
                name="website"
                onChange={userInfoChange}
                value={user.website}
                placeholder="Site internet"
                onBlur={() => {
                  user.website === "" ? setUser({ ...user, website: userData.website }) : setUser({ ...user, website: user.website });
                }}
                onFocus={() => {
                  user.website === userData.website ? setUser({ ...user, website: "" }) : setUser({ ...user, website: user.website });
                }}
              />
            </InputWrapper>



            <button
              className="copyButton"
              onClick={() => {
                copyToClipboard();
                responseCopy();
              }}
            ><p className="btnCopy"></p></button>
          </div>
        </section>
      </Container>
    </Wrapper>
  );
}
