import React from "react";

const config = require("../../../config.json")
const compagnyColorSecondary = config.APP_COMPAGNYCOLORSECONDARY;

const Mail = {
  margin: "0px 0px 0px 0px",
  fontSize: "12px",
  lineHeight: "1.38",
  textDecoration: "none",
  paddingTop: "3px",
};
const MailLink = {
  textDecoration: "none",
  color: compagnyColorSecondary,
  fontSize: "12px",
  margin: "0px 0px 0px",
  fontWeight: "700",
};

export default function Email(email) {
  return (
    <p style={Mail}>
      <a href={`mailto:${email.email}`} style={MailLink}>
        {email.email}
      </a>
    </p>
  );
}
